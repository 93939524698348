import React, { Component } from 'react';

let acceptCookies;

function realSetCookie(key, val, exp) {
	exp = exp ? ";expires=" + exp.toUTCString() : "";
	document.cookie = key + "=" + val + exp + ";path=/";
}

export let cookieStorage = new Promise((resolve) => {
	if (getCookie("consent")) {
		let d = new Date();
		d.setTime(d.getTime() + (300 * 24 * 60 * 60 * 1000));
		realSetCookie("consent", 1, d);
		resolve();
	}
	else
		acceptCookies = resolve;
});

export function getCookie(key) {
	var i, x, y, ARRcookies = document.cookie.split(";");
	for (i = 0; i < ARRcookies.length; i++) {
		x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
		y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
		x = x.replace(/^\s+|\s+$/g, "");
		if (x === key)
			return y;
	}
}

export function setCookie(key, val, exp) {
	if (exp) {
		let d = new Date();
		d.setTime(d.getTime() + (exp * 24 * 60 * 60 * 1000));
		exp = d;
	}
	cookieStorage.then(() => realSetCookie(key, val, exp));
}

export default class Consent extends Component {
	state = {
		isShow: true
	}

	acceptCookiesHandler = () => {
		setCookie("consent", 1, 300);
		acceptCookies();
		this.closeCookiesHandler();
	}

	closeCookiesHandler = () => {
		document.querySelector("#cookies").classList.toggle("close", this.setState({
			isShow: !this.state.isShow
		}))

	}

	render = getCookie("consent") ? () => "" : () => (
		<div id="cookies">
			<div className="container cookies_container">
				<div className=" row cookies_content">
					<div className="close_btn" onClick={() => this.closeCookiesHandler()}></div>
					<div className="col-12 col-md-7 has-padding">
						<p>
							This site uses cookies. By continuing to use this website,
							you agree to their use. To find out more see here:{" "}
							<a href="/cookies" target="_blank" rel="noopener">
								Cookie policy.
							</a>
						</p>
					</div>
					<div className="col-12 col-md-5 text-center">
						<div className="button inview" onClick={() => this.acceptCookiesHandler()}>
							ACCEPT COOKIES
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

