import './css/base.css';
import raf from 'raf';
import 'nodelist-foreach-polyfill';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { cookieStorage, getCookie, setCookie } from './components/cookies';
raf.polyfill();

var loader,
	dynContent = window.fetch(`${process.env.REACT_APP_CMS_URL}/api`)
		.then((rsp) => rsp.json());

cookieStorage.then(() => {
	window.dataLayer = window.dataLayer || [];

	function gtag() {
		window.dataLayer.push(arguments);
	}

	gtag('js', new Date());
	gtag('config', 'UA-135004162-1');
});

if (
	getCookie("seenIntro") !== "true"
	&& window.location.pathname === "/"
)
	loader = import('./intro');
else
	loader = import('./react-ep');

loader.then((m) => { m.default(dynContent).then(() => {
	setCookie("seenIntro", "true");
	document.body.classList.add("loaded");
	setTimeout(() => {
		requestAnimationFrame(() => {
			document.querySelector("#App>nav").style.opacity = 1;
			setTimeout(() => {
				document.getElementById("content").style.opacity = 1;
				setTimeout(() =>
					document
						.querySelectorAll("body>:not(#root):not(#intro)")
						.forEach((e) => e.style.opacity = 1),
					600
				);
			}, 600);
		});
	}, 200);
})});
